import { AppThunk } from "store";
import { validateJobNumber } from "services/api/quotes";
import { setCurrentQuoteReferenceIsValid } from "../index.slice";

export const validateJobNumberThunk = (
  adminSalesAccountUUID: string,
  currentQuoteReference: string
): AppThunk => {
  return async (dispatch, getState) => {
    const { data, error } = await validateJobNumber(adminSalesAccountUUID, {
      jobNumber: currentQuoteReference,
    });
    if (!error && data) {
      dispatch(setCurrentQuoteReferenceIsValid(data.isValid));
    } else {
      return;
    }
  };
};
