import {
  BulkUploadColumnName,
  BulkUploadItemFieldNames,
  BulkUploadPackageFieldNames,
} from "enum/bulk-upload-column-names.enum";
import { CarrierErrorCodes } from "enum/error-codes/carrier-error-codes.enum";
import { CarrierLabelErrorDictionary } from "enum/error-codes/carrier-label-error-codes.enum";
import { GenericErrorCodes } from "enum/error-codes/generic-error-codes.enum";
import { RuleErrorCodes } from "enum/error-codes/rule-error-codes.enum";
import { ShipmentConsignmentErrorCodes } from "enum/error-codes/shipment-consignment-error-codes.enum";
import { ShipmentItemErrorCodes } from "enum/error-codes/shipment-item-error-codes.enum";
import { ZoneErrorCodes } from "enum/error-codes/zone-error-codes.enum";
import {
  IShipment,
  IShipmentItemDescription,
  ShipmentErrors,
} from "models/shipment/shipment.model";
import { IShipmentPackage } from "models/shipmentPackage/shipmentPackage.model";
import { PackageErrorCodes } from "enum/error-codes/package-error-codes.enum";

export const isShipmentError = (fieldName: BulkUploadColumnName) => {
  return (
    fieldName === BulkUploadColumnName.customerReference ||
    fieldName === BulkUploadColumnName.reasonForExport ||
    fieldName === BulkUploadColumnName.fromAddressFirstName ||
    fieldName === BulkUploadColumnName.fromAddressLastName ||
    fieldName === BulkUploadColumnName.fromAddressCompany ||
    fieldName === BulkUploadColumnName.fromAddressPhone ||
    fieldName === BulkUploadColumnName.fromAddressEmail ||
    fieldName === BulkUploadColumnName.fromAddressStreet1 ||
    fieldName === BulkUploadColumnName.fromAddressStreet2 ||
    fieldName === BulkUploadColumnName.fromAddressStreet3 ||
    fieldName === BulkUploadColumnName.fromAddressCity ||
    fieldName === BulkUploadColumnName.fromAddressCountyState ||
    fieldName === BulkUploadColumnName.fromAddressZip ||
    fieldName === BulkUploadColumnName.fromAddressCountryIso ||
    fieldName === BulkUploadColumnName.toAddressFirstName ||
    fieldName === BulkUploadColumnName.toAddressLastName ||
    fieldName === BulkUploadColumnName.toAddressCompany ||
    fieldName === BulkUploadColumnName.toAddressPhone ||
    fieldName === BulkUploadColumnName.toAddressEmail ||
    fieldName === BulkUploadColumnName.toAddressStreet1 ||
    fieldName === BulkUploadColumnName.toAddressStreet2 ||
    fieldName === BulkUploadColumnName.toAddressStreet3 ||
    fieldName === BulkUploadColumnName.toAddressCity ||
    fieldName === BulkUploadColumnName.toAddressCountyState ||
    fieldName === BulkUploadColumnName.toAddressZip ||
    fieldName === BulkUploadColumnName.toAddressCountryIso
  );
};

export const isPackageError = (fieldName: BulkUploadPackageFieldNames) => {
  return (
    fieldName === BulkUploadPackageFieldNames.packageType ||
    BulkUploadPackageFieldNames.packageWidth ||
    BulkUploadPackageFieldNames.packageHeight ||
    BulkUploadPackageFieldNames.packageLength ||
    BulkUploadPackageFieldNames.packageWeight
  );
};

export const isItemError = (fieldName: BulkUploadItemFieldNames) => {
  return (
    fieldName === BulkUploadItemFieldNames.itemDescription ||
    fieldName === BulkUploadItemFieldNames.itemOriginCountryIso ||
    fieldName === BulkUploadItemFieldNames.itemQuantity ||
    fieldName === BulkUploadItemFieldNames.itemValue ||
    fieldName === BulkUploadItemFieldNames.itemValueCurrency ||
    fieldName === BulkUploadItemFieldNames.itemSku ||
    fieldName === BulkUploadItemFieldNames.itemHsCode ||
    fieldName === BulkUploadItemFieldNames.itemWeight ||
    fieldName === BulkUploadItemFieldNames.manufacturerDetails
  );
};

export const getFirstShipmentError = (shipment: IShipment): string => {
  const { packages, errors } = shipment;
  if (hasConsignmentErrors(errors)) {
    const firstError = Object.keys(errors.consignment)[0];

    return isShipmentError(firstError as BulkUploadColumnName)
      ? `${firstError}: ${PrintConsignmentShipmentErrors(
          errors.consignment[firstError][0].code,
          shipment[firstError]
        )}`
      : isItemError(firstError as BulkUploadItemFieldNames)
      ? PrintConsignmentItemErrors(
          errors.consignment[firstError][0].code,
          shipment.itemDescriptions[0]
        )
      : UNHANDLED_ERROR_MESSAGE;
  }

  if (hasCarrierErrors(errors))
    return PrintCarrierErrors(errors.carrier[0].code);

  if (hasLabelErrors(packages)) return packages[0].errors[0]?.raw[0].message;
  // The above is a temporary solution until errors are
  // properly coming through then go back to using bottom method

  // return PrintLabelErrors(packages[0].errors[0].code);
  // Limit errors
  if (hasPackageErrors(errors)) {
    const firstPackageWithErrors = errors.packages.find(
      (shipmentPackage) => shipmentPackage.isValid === false
    );

    // if (firstPackageWithErrors.errors.limits.length > 0) {
    //   return firstPackageWithErrors.errors.limits[0].code;
    // }

    // if (
    //   Object.keys(firstPackageWithErrors.errors).filter(
    //     (key) => key !== "limits"
    //   ).length > 0
    // ) {
    //   return firstPackageWithErrors.errors[
    //     Object.keys(firstPackageWithErrors.errors).filter(
    //       (key) => key !== "limits"
    //     )[0]
    //   ]?.code;
    // }

    if (errors?.packages[0]?.errors?.packageWeight?.length > 0) {
      return errors?.packages[0]?.errors?.packageWeight[0].code ===
        PackageErrorCodes.ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT
        ? "Item weight exceeds package weight."
        : errors?.packages[0]?.errors?.packageWeight[0]?.code;
    }

    if (firstPackageWithErrors?.packageItems?.length > 0) {
      const firstPackageItemWithErrors = errors.packages[0].packageItems.find(
        (shipmentPackageItem) => shipmentPackageItem.isValid === false
      );

      return JSON.stringify(firstPackageItemWithErrors?.errors);
    }
  }

  if (hasZoneErrors(errors)) return PrintZoneErrors(errors.zone[0].code);

  if (hasRuleErrors(errors)) return PrintRuleErrors(errors.rule[0].code);
};

export const UNHANDLED_ERROR_MESSAGE = "Unknown error. Please contact us.";

export const PrintLabelErrors = (errorCode: CarrierLabelErrorDictionary) => {
  switch (errorCode) {
    case CarrierLabelErrorDictionary.INVALID_EORI_NUMBER:
      return "EORI number was rejected by the carrier.";

    case CarrierLabelErrorDictionary.INVALID_HS_CODE:
      return "HS code was rejected by the carrier.";

    default:
      return "UNHANDLED_ERROR_MESSAGE";
  }
};

export const PrintConsignmentItemErrors = (
  errorCode:
    | ShipmentItemErrorCodes
    | GenericErrorCodes
    | ShipmentConsignmentErrorCodes,
  item: IShipmentItemDescription
) => {
  switch (errorCode) {
    case GenericErrorCodes.FIELD_REQUIRED:
      return `this field is required`;

    case ShipmentItemErrorCodes.ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT:
      return `the item weight ${item.weight} exceeds the package weight of x`;

    case ShipmentConsignmentErrorCodes.INVALID_COUNTRY:
      return `invalid country code provided`;

    case ShipmentConsignmentErrorCodes.INVALID_ZIP:
      return `invalid zip provided`;

    case ShipmentConsignmentErrorCodes.INVALID_EMAIL:
      return `Invalid email provided`;

    case ShipmentConsignmentErrorCodes.INVALID_EXPORT_REASON:
      return `invalid export reason provided`;

    default:
      return UNHANDLED_ERROR_MESSAGE;
  }
};

export const PrintPackageErrors = (
  errorCode:
    | ShipmentItemErrorCodes
    | GenericErrorCodes
    | ShipmentConsignmentErrorCodes,
  item: IShipmentItemDescription
) => {
  switch (errorCode) {
    case ShipmentItemErrorCodes.ITEM_WEIGHT_EXCEEDS_PACKAGE_WEIGHT:
      return `The item weight ${item.weight} exceeds the package weight of x`;

    default:
      return UNHANDLED_ERROR_MESSAGE;
  }
};

export const PrintConsignmentShipmentErrors = (
  errorCode:
    | ShipmentConsignmentErrorCodes
    | GenericErrorCodes
    | ShipmentItemErrorCodes,
  value: string | number
) => {
  switch (errorCode) {
    case GenericErrorCodes.FIELD_REQUIRED:
      return "this field is required";

    case GenericErrorCodes.INVALID_NUMBER:
      return "this field is required";

    case GenericErrorCodes.INVALID_STRING:
      return "this field is required";

    case ShipmentConsignmentErrorCodes.INVALID_COUNTRY:
      return `Country name or code ${value} is invalid`;

    case ShipmentConsignmentErrorCodes.INVALID_EMAIL:
      return `Email ${value} is not a valid email`;

    case ShipmentConsignmentErrorCodes.INVALID_ZIP:
      return `Zip ${value} is invalid`;

    default:
      return UNHANDLED_ERROR_MESSAGE;
  }
};

export const PrintZoneErrors = (errorCode: ZoneErrorCodes) => {
  switch (errorCode) {
    case ZoneErrorCodes.ZONE_SERVICE_NOT_AVAILABLE_FOR_ROUTE:
      return "Service not available for this shipping route.";

    default:
      return UNHANDLED_ERROR_MESSAGE;
  }
};

export const PrintCarrierErrors = (errorCode: CarrierErrorCodes) => {
  switch (errorCode) {
    case CarrierErrorCodes.CARRIER_NOT_AVAILABLE_FOR_ROUTE:
      return "Carrier not available for route.";

    case CarrierErrorCodes.DDP_NOT_ALLOWED:
      return "DDP not allowed.";

    default:
      return UNHANDLED_ERROR_MESSAGE;
  }
};

export const PrintRuleErrors = (errorCode: RuleErrorCodes) => {
  switch (errorCode) {
    case RuleErrorCodes.RULE_SHIPPING_ROUTE_NOT_FOUND:
      return "No services found for this shipment. Try generating a quote or re-run rules.";

    default:
      return UNHANDLED_ERROR_MESSAGE;
  }
};

export const hasErrors = (shipment: IShipment): boolean => {
  return (
    shipment &&
    shipment.errors &&
    (hasConsignmentErrors(shipment.errors) ||
      hasCarrierErrors(shipment.errors) ||
      hasPackageErrors(shipment.errors) ||
      hasRuleErrors(shipment.errors) ||
      hasLabelErrors(shipment.packages) ||
      hasZoneErrors(shipment.errors))
  );
};

export const hasConsignmentErrors = (
  shipmentErrors: ShipmentErrors | null
): boolean => {
  return shipmentErrors && Object.keys(shipmentErrors?.consignment).length > 0
    ? true
    : false;
};

export const hasLabelErrors = (packages: IShipmentPackage[]): boolean => {
  return packages?.filter((p) => p.errors && p.errors.length > 0).length > 0;
};

export const hasPackageErrors = (
  shipmentErrors: ShipmentErrors | null
): boolean => {
  return shipmentErrors.packages.filter(
    (shipmentPackage) => shipmentPackage.isValid === false
  ).length > 0
    ? true
    : false;
};

export const hasRuleErrors = (
  shipmentErrors: ShipmentErrors | null
): boolean => {
  return shipmentErrors && Object.keys(shipmentErrors?.rule).length > 0
    ? true
    : false;
};

export const hasZoneErrors = (
  shipmentErrors: ShipmentErrors | null
): boolean => {
  return shipmentErrors && Object.keys(shipmentErrors?.zone).length > 0
    ? true
    : false;
};

export const hasCarrierErrors = (
  shipmentErrors: ShipmentErrors | null
): boolean => {
  return shipmentErrors && Object.keys(shipmentErrors?.carrier).length > 0
    ? true
    : false;
};
