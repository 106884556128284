import { IQuotedRate } from "models/quote/quote.model";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { RatesLoadingPanel } from "./RatesLoadingPanel";
import { RatesErrorPanel } from "./RatesErrorPanel";
import { RatesEmpty } from "./RatesEmpty";
import { RatesList } from "./RatesList";

export type IRatesProps = {
  rates: IQuotedRate[];
  checkedRates: IQuotedRate[];
  isCheckable?: boolean;
  isInternationalShipment: boolean;
  onSelectRate?(rate: IQuotedRate): void;
  onCheckRate?(rate: IQuotedRate): void;
  allowSelect?: boolean;
  selectedRate?: IQuotedRate | null;
  loadingStatus: ApiLoadingStatus;
  handleRefetch?(): void;
};

export const Rates = (props: IRatesProps) => {
  const { loadingStatus, rates, handleRefetch } = props;

  return (
    <>
      {loadingStatus === ApiLoadingStatus.LOADING ? (
        <RatesLoadingPanel />
      ) : loadingStatus === ApiLoadingStatus.FAILED ? (
        <RatesErrorPanel handleRefetch={handleRefetch} />
      ) : rates.length > 0 ? (
        <div>
          <RatesList {...props} />
        </div>
      ) : (
        <RatesEmpty />
      )}
    </>
  );
};
