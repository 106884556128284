import { Divider } from "components/atoms/Divider";
import { ModalBody, ModalHeader } from "components/atoms/Modal";
// import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";

export const ContactInfoModal = () => {
  return (
    <>
      <ModalHeader>Get Help</ModalHeader>
      <ModalBody>
        <div className="flex items-center">
          <div className="mr-2">
            <MdOutlineMail className="w-10 h-10" />
          </div>
          <div>
            Email:{" "}
            <a
              style={{ color: "rgb(59 130 246)" }}
              className="text-blue-500 font-medium"
              href="mailto:support@connexx.co.uk"
            >
              support@connexx.co.uk
            </a>
            <p className="text-sm mt-1">
              Support hours are Monday - Friday, 9am - 5pm GMT
            </p>
          </div>
        </div>

        {/* <div className="mt-5 mb-5">
          <Divider />
        </div>
        <div className="flex items-center">
          <div className="mr-2">
            <MdOutlineMail className="w-10 h-10" />
          </div>
          <div>
            Email:{" "}
            <a
              style={{ color: "rgb(59 130 246)" }}
              className="text-blue-500 font-medium"
              href="mailto:contact@connexx.com"
            >
              Email
            </a>
          </div>
        </div> */}
      </ModalBody>
    </>
  );
};
