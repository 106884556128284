import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { useHotkeys } from "react-hotkeys-hook";
import { Button } from "../Button";
import { Stack } from "../Stack";
import { useModalContext } from "./ModalContext";

export type IModalFooterButtonsProps = {
  onSubmit(): void;
  onCancel(): void;
  primaryLabel: string;
  isLoading?: boolean;
  isPrimaryDisabled?: boolean;
};

export const ModalFooterButtons = ({
  onCancel,
  onSubmit,
  primaryLabel,
  isLoading,
  isPrimaryDisabled,
}: IModalFooterButtonsProps) => {
  const { handleClose } = useModalContext();

  const ref = useHotkeys<HTMLButtonElement>(KeyboardKeys.ENTER, () => {
    handleClose && handleClose();
  });

  return (
    <div className="ml-auto">
      <Stack direction="horizontal" spacing={3}>
        <Button onClick={onCancel} innerRef={ref}>
          Cancel
        </Button>
        <Button
          isDisabled={isPrimaryDisabled}
          onClick={onSubmit}
          isLoading={isLoading}
          colorScheme="primary"
        >
          {primaryLabel}
        </Button>
      </Stack>
    </div>
  );
};
