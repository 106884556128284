import React, { useState, useEffect, useCallback } from "react";
import { Search, Loader2 } from "lucide-react";
import { debounce } from "lodash";
import { Input } from "components/atoms/Input";
import { useTheme } from "styled-components";
import iso from "iso-3166-1";
import { addressLookup, selectedAddressLookup } from "services/api/addresses";
import { RootState } from "store";
import { useSelector } from "react-redux";

export const AddressTypeahead = ({
  isoCode,
  setQuery,
  query,
  populateAddressData,
}): JSX.Element => {
  const theme = useTheme();
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const convertIso2ToIso3 = (alpha2Code) => {
    const country = iso.whereAlpha2(alpha2Code);
    return country ? country.alpha3 : null;
  };

  // Fetch Address Suggestions (Debounced)
  const fetchSuggestions = useCallback(
    debounce(async (searchTerm, isoCode) => {
      if (!searchTerm) {
        setSuggestions([]);
        return;
      }

      setLoading(true);
      try {
        const response = await addressLookup(salesAccountUUID, {
          query: searchTerm,
          countryCode: isoCode,
        });
        const fetchedSuggestions = response?.data?.result?.hits.map(
          (element) => ({
            id: element.id,
            suggestion: element.suggestion,
          })
        );
        setSuggestions(fetchedSuggestions || []);
      } catch (error) {
        console.error("Error fetching suggestions:", error);
        setSuggestions([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  // Fetch Detailed Address Information
  const fetchAddressDetails = async (id: string) => {
    try {
      const response = await selectedAddressLookup(salesAccountUUID, { id });
      populateAddressData(response.data, isoCode);
    } catch (error) {
      console.error("Error fetching address details:", error);
    }
  };

  // Handle Input Change
  const handleChange = (e) => {
    setQuery(e.target.value);
    fetchSuggestions(e.target.value, convertIso2ToIso3(isoCode));
    setSelectedIndex(-1); // Reset selection on new input
  };

  // Handle Keyboard Navigation
  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      setSelectedIndex((prev) => Math.min(prev + 1, suggestions.length - 1));
    } else if (e.key === "ArrowUp") {
      setSelectedIndex((prev) => Math.max(prev - 1, 0));
    } else if (e.key === "Enter" && selectedIndex >= 0) {
      const selectedItem = suggestions[selectedIndex];
      setQuery(selectedItem.suggestion);
      setSuggestions([]);
      fetchAddressDetails(selectedItem.id);
    }
  };

  // Handle Selection
  const handleSelect = async (selectedItem) => {
    setQuery(selectedItem.suggestion);
    setSuggestions([]);
    fetchAddressDetails(selectedItem.id);
  };
  return (
    <div className="w-full max-w-lg mx-auto relative">
      {/* Search Input */}
      <Input
        type="text"
        placeholder="Enter postcode or address..."
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {loading && <Loader2 className="w-5 h-5 text-gray-500 animate-spin" />}

      {/* Suggestions Dropdown */}
      {suggestions.length > 0 && (
        <ul
          className="absolute w-full shadow-lg rounded-lg overflow-hidden z-10 border"
          style={{
            borderRadius: theme.borderRadius,
            backgroundColor: theme.dropdownBackgroundColor,
            borderColor: theme.dropdownBorderColor,
            boxShadow: theme.shadows["2xl"],
          }}
        >
          {suggestions.map((item, index) => (
            <li
              key={item.id}
              className={`px-4 py-2 hover:bg-gray3 cursor-pointer transition ${
                selectedIndex === index ? "bg-gray3" : ""
              }`}
              onClick={() => handleSelect(item)}
            >
              {item.suggestion}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
