import { FormControl } from "components/molecules/FormControl";
import { useRef } from "react";
import { RuleFormConditions } from "./RuleFormConditions";
import { RuleFormActions } from "./RuleFormActions";
import { ModalBody, ModalFooter } from "components/atoms/Modal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  setName,
  setVirtualWarehouseId,
} from "store/createOrEditRuleSlice/index.slice";
import { ModalFooterButtons } from "components/atoms/Modal/ModalFooterButtons";
import { Stack } from "components/atoms/Stack";
import { FocusLock } from "components/atoms/FocusLock";
import { Input } from "components/atoms/Input";
import { Select } from "components/atoms/Select";
import { generateSelectOptions } from "components/atoms/Select/UseSelect";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";

export const RuleForm = ({
  handleSubmit,
  isSubmitting,
  submitButtonLabel,
  onCancel,
}: {
  handleSubmit(): void;
  isSubmitting: boolean;
  submitButtonLabel: string;
  onCancel(): void;
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const dispatch = useDispatch();

  const { values, errors } = useSelector((state: RootState) => {
    return state.createOrEditRuleSlice;
  });

  const { items } = useSelector((state: RootState) => {
    return state.virtualWarehousesSlice;
  });

  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const virtualWarehouseSelectOptions = generateSelectOptions({
    data: items,
    labelKey: "name",
    valueKey: "id",
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  return (
    <FocusLock>
      <div ref={ref}>
        <ModalBody>
          <Stack spacing={4}>
            <FormControl label="Name" errorMessage={errors.name}>
              <Input
                placeholder="Name"
                value={values.name}
                tabIndex={1}
                onChange={(e) => dispatch(setName(e.target.value))}
              />
            </FormControl>

            <FormControl label="Virtual warehouse" errorMessage={errors.name}>
              <Select
                options={virtualWarehouseSelectOptions}
                value={virtualWarehouseSelectOptions.find(
                  (warehouse) => warehouse.id === values.virtualWarehouseId
                )}
                onChange={({ value }) => {
                  dispatch(setVirtualWarehouseId(value as number));
                }}
              />
            </FormControl>
            <RuleFormConditions />
            <RuleFormActions />
          </Stack>
        </ModalBody>
        <ModalFooter>
          <ModalFooterButtons
            primaryLabel={submitButtonLabel}
            onSubmit={handleSubmit}
            isLoading={isSubmitting}
            onCancel={onCancel}
            isPrimaryDisabled={!permissions.rules.canSaveRule()}
          />
        </ModalFooter>
      </div>
    </FocusLock>
  );
};
