import { ReactNode } from "react";
import { DangerousGoodsSelect } from "components/molecules/DangerousGoodsSelect";
import {
  setDangerousGoodsOption,
  setPackages,
} from "store/createShipmentSlice/index.slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { isUserAdminForSalesAccounts } from "utils/isUserAdminForSalesAccounts";
import { ChangeDefaultUnit } from "components/atoms/PrintWeight/changeWeightType";

export const NumberedSectionHeader = ({
  number,
  children,
  isQuote,
}: {
  number: number;
  children: ReactNode;
  isQuote: boolean;
}) => {
  const dispatch = useDispatch();

  const { dangerousGoodsOption, values, errors } = useSelector(
    (state: RootState) => {
      return state.createShipmentSlice;
    }
  );

  const { loggedInUser, salesAccountID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { items } = useSelector((state: RootState) => {
    return state.virtualWarehousesSlice;
  });

  const item = items.find((element) => element.isDefault) || null;

  const containsFedex = [];
  item?.whitelists.forEach((element) => {
    if (element.carrierId === "24") {
      containsFedex.push(element);
    }
  });

  const { financialCompany, salesAccountUUID } = useSelector(
    (state: RootState) => {
      return state.authSlice;
    }
  );
  const { settings } = useSelector((state: RootState) => {
    return state.settingsSlice;
  });
  const handleToggle = (event) => {
    const selectedUnit = event.target.value;
    ChangeDefaultUnit(selectedUnit, salesAccountUUID, dispatch);
  };

  return (
    <header className="flex mb-3  items-center">
      <div className="mr-3">
        <div className="inline-flex items-center justify-center text-sm font-bold leading-none text-white rounded-full w-7 h-7 bg-blue9">
          {number}
        </div>
      </div>
      <div className="flex flex-row justify-between w-full">
        <div className="flex items-center text-lg font-semibold leading-none">
          {children}
        </div>
        {financialCompany == 8 && children === "Packages" && !isQuote && (
          <div className="flex items-center">
            <div className="flex mr-4 items-center text-xs">
              <label htmlFor="kg-cm">
                <input
                  type="radio"
                  id="kg-cm"
                  name="units"
                  value="kg"
                  checked={settings?.weightUnit === "kg"}
                  onChange={handleToggle}
                  className="mr-1"
                />
                KG/CM
              </label>
            </div>
            <div className="flex ml-4 items-center text-xs">
              <label htmlFor="lb-in">
                <input
                  type="radio"
                  id="lb-in"
                  name="units"
                  value="pound"
                  checked={settings?.weightUnit === "pound"}
                  onChange={handleToggle}
                  className="mr-1"
                />
                LB/IN
              </label>
            </div>
          </div>
        )}
        {children === "Packages" &&
        !isQuote &&
        (isUserAdminForSalesAccounts(loggedInUser) ||
          salesAccountID.toString() === "225844" ||
          salesAccountID.toString() === "2223") &&
        containsFedex.length ? (
          <div className="flex flex-col">
            <div className="flex flex-row">
              <div className="flex mr-4 items-center">
                Dangerous Goods Category:
              </div>
              <div style={{ width: 300 }}>
                <DangerousGoodsSelect
                  value={dangerousGoodsOption}
                  onChange={(option) => {
                    dispatch(setDangerousGoodsOption(option));
                    const clone = values.items.map((item) => {
                      return {
                        ...item,
                        dgPackage: true,
                        lithiumCategory: "",
                        dryIceWeight: 0,
                        dgunNumber: 0,
                        class: "",
                        packingGroup: "",
                        dgNetWeight: 0,
                        dgVolumeMl100: 0,
                      };
                    });

                    dispatch(setPackages(clone));
                  }}
                />
              </div>
            </div>
            <div className="flex justify-end mt-1 text-sm text-red10">
              {errors.details?.dangerousGoods}
            </div>
          </div>
        ) : null}
      </div>
    </header>
  );
};
