import { apiPaths, customerApiBase } from "enum/paths.enum";
import { Query } from "interfaces/api.interface";
import { IAddress } from "models/address/address.model";
import { ISalesAccount } from "models/salesAccount/salesAccount.model";
import { del } from "services/del";
import { fetchAll, fetchSingle } from "services/get";
import { patch } from "services/patch";
import { post } from "services/post";
import { ICreateAddressDto } from "./dtos/create-address.request.dto";
import { FetchAddressesResponseDto } from "./dtos/fetch-addresses.response.dto";
// IFetchResponseSingle;
import { IFetchResponseSingle } from "services/api";

export const fetchAddresses = async (
  salesAccountUUID: ISalesAccount["uuid"],
  query?: Query<IAddress>
): Promise<FetchAddressesResponseDto> => {
  return await fetchAll(
    customerApiBase + "/" + salesAccountUUID + apiPaths.addresses,
    query
  );
};

export const createAddress = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values: ICreateAddressDto
): Promise<any> => {
  return await post(
    customerApiBase + "/" + salesAccountUUID + apiPaths.addresses,
    values
  );
};

export const updateAddress = async (
  salesAccountUUID: ISalesAccount["uuid"],
  addressUUID: IAddress["uuid"],
  values: ICreateAddressDto
): Promise<any> => {
  return await patch(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.addresses +
      "/" +
      addressUUID,
    values
  );
};

export const deleteAddress = async (
  salesAccountUUID: ISalesAccount["uuid"],
  addressUUID: IAddress["uuid"]
): Promise<any> => {
  return await del(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.addresses +
      "/" +
      addressUUID
  );
};

export const setDefaultAddress = async (
  salesAccountUUID: ISalesAccount["uuid"],
  addressUUID: IAddress["uuid"]
): Promise<any> => {
  return await patch(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.addresses +
      "/" +
      addressUUID +
      "/default",
    {}
  );
};

export const addressLookup = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values
): Promise<any> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.addresses +
      "/addressLookup",
    values
  );
};

export const selectedAddressLookup = async (
  salesAccountUUID: ISalesAccount["uuid"],
  values
): Promise<any> => {
  return await post(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.addresses +
      "/selectedAddressLookup",
    values
  );
};

export const getDefaultAddress = async (
  salesAccountUUID: ISalesAccount["uuid"]
): Promise<IFetchResponseSingle<IAddress>> => {
  const response = await fetchSingle<IAddress>(
    customerApiBase + "/" + salesAccountUUID + apiPaths.addresses + "/default"
  );
  return response;
};

export const removeDefaultAddress = async (
  salesAccountUUID: ISalesAccount["uuid"],
  addressUUID: IAddress["uuid"]
): Promise<any> => {
  return await del(
    customerApiBase +
      "/" +
      salesAccountUUID +
      apiPaths.addresses +
      "/" +
      addressUUID +
      "/default"
  );
};
