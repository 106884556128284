import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IThemeNames } from "theme/theme.type";
import {
  LocalStorageKeys,
  setLocalStorageItem,
} from "utils/react/localStorage";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { tableRowQuantity } from "enum/table-row-quantity.enum";

interface ITableSlice {
  isFilterSidebarOpen: boolean;
  theme: IThemeNames;
  numberOfRows: ISelectOption<object, tableRowQuantity>[];
  numberOfRowsSelected: ISelectOption<object, tableRowQuantity>;
}

const initialState: ITableSlice = {
  isFilterSidebarOpen: true,
  theme: "dark",
  numberOfRows: [
    { label: "10", value: 10 },
    { label: "25", value: 25 },
    { label: "50", value: 50 },
    { label: "100", value: 100 },
    { label: "500", value: 500 },
  ],
  numberOfRowsSelected: { label: "50", value: 50 },
};

export const tableSlice = createSlice({
  name: "tableSlice",
  initialState,
  reducers: {
    setFilterSidebarOpenStatus: (state, action: PayloadAction<boolean>) => {
      state.isFilterSidebarOpen = action.payload;

      setLocalStorageItem(
        LocalStorageKeys.CONNEXX_SHOW_TABLE_FILTERS,
        action.payload.toString()
      );
    },

    setTheme: (state, action: PayloadAction<ITableSlice["theme"]>) => {
      var currentTheme = document.documentElement.getAttribute("data-theme");

      var targetTheme = "light";

      if (currentTheme === "light") {
        targetTheme = "dark";
      }

      setLocalStorageItem(LocalStorageKeys.CONNEXX_THEME, action.payload);

      document.documentElement.setAttribute("data-theme", targetTheme);

      state.theme = action.payload;
    },

    setNumberOfRowsSelected: (
      state,
      action: PayloadAction<ITableSlice["numberOfRowsSelected"]>
    ) => {
      state.numberOfRowsSelected = action.payload;
    },
  },
});

export const { setFilterSidebarOpenStatus, setTheme, setNumberOfRowsSelected } =
  tableSlice.actions;
