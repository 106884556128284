import { SalesAccountStatus } from "enum/sales-account-status.enum";
import { ShipmentExportReasons } from "enum/shipment-export-reasons.enum";
import { DimensionUnits } from "interfaces/dimension-units.type";
import { WeightUnits } from "interfaces/weight-units.type";
import { ThemeColorPalettes } from "theme/theme-colors.theme";

export const CustomerStatusesConfig: {
  [key in SalesAccountStatus]: { color: ThemeColorPalettes; label: string };
} = {
  [SalesAccountStatus.NEW]: {
    color: "gray",
    label: "NEW",
  },
  [SalesAccountStatus.ACTIVE]: {
    color: "green",
    label: "ACTIVE",
  },
  [SalesAccountStatus.STOPPED]: {
    color: "red",
    label: "STOPPED",
  },
};

export type ISalesAccountDefaults = {
  dimensionsUnit: DimensionUnits;
  weightUnit: WeightUnits;
  enhancedLiability: null;
  printSettings: string;
  height: number | null;
  width: number | null;
  length: number | null;
  weight: number | null;
  customsValue: number | null;
  notificationEmail: string | null;
  notificationPhone: string | null;
  itemDescription: string | null;
  itemsCurrency: string | null;
  itemValue: number | null;
  commodityCode: string | null;
  countryOfOrigin: string | null;
  manufacturerDetails: string | null;
  reasonForExport: ShipmentExportReasons;
  useDefaultPackageDimensionsInUi?: boolean;
  divideTotalPackageWeightByQuantityInUi?: boolean;
  useAccountFromAddressForUPSSuppliers?: boolean;
  enablePrintPackingSlip?: boolean;
  shouldGroupItemsBySkuForPackingSlip?: boolean;
  UKIMSNumber?: string | null;
};

export type ISalesAccount = {
  id: number;
  uuid: string;
  status: SalesAccountStatus | string;
  tradeName: string;
  baseCurrencyCode: string;
  companyName: string;
  salesAccountReference: string;
  defaultEmail: string;
  defaultAddress: {
    addressLine1: string | null;
    addressLine2: string | null;
    addressLine3: string | null;
    city: string | null;
    countryIsoCode: string | null;
    countyState: string | null;
    zip: string | null;
  };
  defaultSettings: ISalesAccountDefaults;
  financialCompany?: number;
  alertType?: string;
};
