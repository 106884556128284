import { IQuotedRate } from "models/quote/quote.model";
import { IShipmentPackageGrouped } from "models/shipmentPackage/shipmentPackage.model";

const ChinaQuoteCustomerSalesAccounts = [
  3, 42, 50, 54, 66, 73, 107, 113, 151, 153, 180, 222, 2735, 366, 434, 439, 474,
  520, 522, 531, 534, 590, 594, 622, 661, 692, 695, 767, 794, 890, 898, 1000,
  3996, 1042, 1172, 1195, 1199, 1358, 1415, 1543, 1583, 1599, 1615, 4839, 34276,
  38572, 38593, 38596, 49384, 68026, 136617, 136627, 165770, 165774, 225089,
  225184, 225185, 225452,
];

export const filterRates = (
  rates: IQuotedRate[],
  senderCountryCode: string,
  receiverCountryCode: string,
  salesAccountUUID: number,
  packages: IShipmentPackageGrouped[] // IShipmentPackageGrouped is not defined]
): IQuotedRate[] => {
  // if the sum of package weights is more than 15kg
  const totalWeight = packages.reduce((acc, curr) => {
    return acc + curr.weight;
  }, 0);

  const weightInKG = totalWeight / 1000 / 100;

  // if the from country is China or hong kong
  if (
    (ChinaQuoteCustomerSalesAccounts.includes(Number(salesAccountUUID)) &&
      senderCountryCode === "CN") ||
    senderCountryCode === "HK"
  ) {
    return rates.filter((rate) => {
      //106 - "EP Global"
      //125 - "K&N"
      const supplierID = Number(rate.supplier.id);

      // if weight is weight of under 15kgs- don't show DHL EP Global
      if (weightInKG <= 15) {
        return supplierID !== 106;
      }

      // if weight is above above 15kg, don't show DHL K&N
      return supplierID !== 125;
    });
  }

  return rates;
};
