import { ModalFooter } from "components/atoms/Modal";
import { ModalFooterButtons } from "components/atoms/Modal/ModalFooterButtons";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { useDispatch } from "react-redux";
import { closeModal } from "store/modalSlice";

export type ICreateQuoteFooterProps = {
  handleSubmit(status: string): void;
  loadingStatus: ApiLoadingStatus;
  isDisabled: boolean;
};

export enum CreateQuoteFooterTestIds {
  "CREATE_QUOTE_FOOTER" = "CREATE_QUOTE_FOOTER",
}

export const CreateQuoteFooter = (props: ICreateQuoteFooterProps) => {
  const { handleSubmit, loadingStatus, isDisabled } = props;

  const dispatch = useDispatch();
  let primaryLabel = "Get rates"; // Default label
  let submitStatus = "INITIAL";

  if (loadingStatus === ApiLoadingStatus.LOADING) {
    primaryLabel = "..."; // Show three dots when loading
  } else if (loadingStatus === ApiLoadingStatus.SUCCESS) {
    primaryLabel = "Save"; // Change to "Save" once loading is complete
    submitStatus = "SUCCESS";
  }
  const onSubmit = () => handleSubmit(submitStatus);
  return (
    <ModalFooter testId={CreateQuoteFooterTestIds.CREATE_QUOTE_FOOTER}>
      <ModalFooterButtons
        onCancel={() => dispatch(closeModal({ modalName: "createShipment" }))}
        onSubmit={onSubmit}
        isLoading={loadingStatus === ApiLoadingStatus.LOADING}
        primaryLabel={primaryLabel}
        isPrimaryDisabled={isDisabled}
      />
    </ModalFooter>
  );
};
