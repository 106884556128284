import { Button } from "components/atoms/Button";
import { Checkbox } from "components/atoms/Checkbox";
import { Divider } from "components/atoms/Divider";
import { FormikForm } from "components/atoms/Form";
import { Input } from "components/atoms/Input";
import { ModalBody, ModalFooter, ModalHeader } from "components/atoms/Modal";
import { Stack } from "components/atoms/Stack";
import { CountrySelect } from "components/molecules/CountrySelect";
import { FormikField } from "components/molecules/Field";
import { FormControl } from "components/molecules/FormControl";
import {
  DEFAULT_FROM_COUNTRY_ISO_CODE,
  GA_DEFAULT_FROM_COUNTRY_ISO_CODE,
} from "constants/misc";
import { useFormSubmit } from "hooks/useFormSubmit";
import { useSelector } from "react-redux";
import { createAddress, updateAddress } from "services/api/addresses";
import {
  CreateAddressValidationSchema,
  ICreateAddressDto,
  ICreateAddressFormValues,
} from "services/api/addresses/dtos/create-address.request.dto";
import { RootState } from "store";
import { PageSection } from "../PageSection";
import { useEffect, useState } from "react";
import { AddressTypeahead } from "components/atoms/AddressTypeahead";
import { Environments, getEnvironment } from "utils/getEnvironment";

export const CreateAddressModal = ({
  onSuccess,
  onClose,
}: {
  onClose(): void;
  onSuccess(): void;
}) => {
  const { editingAddress } = useSelector((state: RootState) => {
    return state.addressSlice;
  });

  const { salesAccountUUID, financialCompany } = useSelector(
    (state: RootState) => {
      return state.authSlice;
    }
  );

  const isEditing = editingAddress;

  const { isSubmitting, onSubmit } = useFormSubmit<ICreateAddressFormValues>({
    submit: async (values) => {
      return (await isEditing)
        ? updateAddress(salesAccountUUID, editingAddress.uuid, values)
        : createAddress(salesAccountUUID, values);
    },
    onSuccess: () => {
      onSuccess();
    },
  });

  const [initialValues, setInitialValues] = useState({
    firstName: isEditing ? editingAddress.firstName : "",
    lastName: isEditing ? editingAddress.lastName : "",
    company: isEditing ? editingAddress.company : "",
    phone: isEditing ? editingAddress.phone : "",
    email: isEditing ? editingAddress.email : "",
    addressLine1: isEditing ? editingAddress.addressLine1 : "",
    addressLine2: isEditing ? editingAddress.addressLine2 : "",
    city: isEditing ? editingAddress.city : "",
    countyState: isEditing ? editingAddress.countyState : "",
    zip: isEditing ? editingAddress.zip : "",
    countryIsoCode: isEditing
      ? editingAddress.countryIsoCode
      : financialCompany !== 8
      ? DEFAULT_FROM_COUNTRY_ISO_CODE
      : GA_DEFAULT_FROM_COUNTRY_ISO_CODE,
    code: isEditing ? editingAddress.code : "",
    branch: isEditing ? editingAddress.branch : "",
    eoriNumber: isEditing ? editingAddress.eoriNumber : "",
    mobile: isEditing ? editingAddress.mobile : "",
    residentialAddress: isEditing ? editingAddress.residentialAddress : false,
    vatNumber: isEditing ? editingAddress.vatNumber : "",
  });

  const [query, setQuery] = useState("");

  const populateAddressData = (data, isoCode, currentValues) => {
    let zip = "";
    let countyState = "";
    let city = "";
    if (isoCode === "US") {
      zip = data.result?.native?.zipCode ? data.result?.native?.zipCode : "";
      countyState = data.result?.native?.state
        ? data.result?.native?.state
        : "";
      city = data.result?.native?.city ? data.result?.native?.city : "";
    } else {
      zip = data.result?.postcode ? data.result?.postcode : "";
      countyState = data.result?.county ? data.result?.county : "";
      city = data.result?.postTown ? data.result?.postTown : "";
    }
    setInitialValues({
      ...currentValues, // Use current form values to preserve user input
      countryIsoCode: isoCode,
      zip,
      countyState,
      city,
      addressLine1: data.result?.line1 ? data.result?.line1 : "",
      addressLine2: `${data.result?.line2 ? data.result?.line2 : ""} ${
        data.result?.line3 ? data.result?.line3 : ""
      }`,
    });
  };

  const supportedCountries = [
    "AF",
    "AL",
    "DZ",
    "AS",
    "AD",
    "AO",
    "AI",
    "AG",
    "AR",
    "AM",
    "AW",
    "AU",
    "AT",
    "AZ",
    "BS",
    "BH",
    "BD",
    "BB",
    "BY",
    "BE",
    "BZ",
    "BJ",
    "BM",
    "BT",
    "BO",
    "BQ",
    "BA",
    "BW",
    "BR",
    "IO",
    "VG",
    "BN",
    "BG",
    "BF",
    "BI",
    "KH",
    "CM",
    "CA",
    "CV",
    "KY",
    "CF",
    "TD",
    "CL",
    "CN",
    "CX",
    "CC",
    "CO",
    "KM",
    "CK",
    "CR",
    "CI",
    "HR",
    "CU",
    "CW",
    "CY",
    "CZ",
    "CD",
    "DK",
    "DJ",
    "DM",
    "DO",
    "EC",
    "EG",
    "SV",
    "GQ",
    "ER",
    "EE",
    "SZ",
    "ET",
    "FK",
    "FO",
    "FM",
    "FJ",
    "FI",
    "FR",
    "GF",
    "PF",
    "GA",
    "GM",
    "GE",
    "DE",
    "GH",
    "GI",
    "GR",
    "GL",
    "GD",
    "GP",
    "GU",
    "GT",
    "GN",
    "GW",
    "GY",
    "HT",
    "HN",
    "HK",
    "HU",
    "IS",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IE",
    "IL",
    "IT",
    "JM",
    "JP",
    "JO",
    "KZ",
    "KE",
    "KI",
    "XK",
    "KW",
    "KG",
    "LA",
    "LV",
    "LB",
    "LS",
    "LR",
    "LY",
    "LI",
    "LT",
    "LU",
    "MO",
    "MG",
    "MW",
    "MY",
    "MV",
    "ML",
    "MT",
    "MH",
    "MQ",
    "MR",
    "MU",
    "YT",
    "MX",
    "MD",
    "MC",
    "MN",
    "ME",
    "MS",
    "MA",
    "MZ",
    "MM",
    "NA",
    "NR",
    "NP",
    "NL",
    "NC",
    "NZ",
    "NI",
    "NE",
    "NG",
    "NU",
    "NF",
    "KP",
    "MK",
    "MP",
    "NO",
    "OM",
    "PK",
    "PW",
    "PA",
    "PG",
    "PY",
    "PE",
    "PH",
    "PN",
    "PL",
    "PT",
    "PR",
    "QA",
    "CG",
    "RE",
    "RO",
    "RU",
    "RW",
    "BL",
    "SH",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "WS",
    "SM",
    "ST",
    "SA",
    "SN",
    "RS",
    "SC",
    "SL",
    "SG",
    "SX",
    "SK",
    "SI",
    "SB",
    "SO",
    "ZA",
    "GS",
    "KR",
    "SS",
    "ES",
    "LK",
    "SD",
    "SR",
    "SJ",
    "SE",
    "CH",
    "SY",
    "TW",
    "TJ",
    "TZ",
    "TH",
    "TL",
    "TG",
    "TK",
    "TO",
    "TT",
    "TN",
    "TR",
    "TM",
    "TC",
    "TV",
    "UG",
    "UA",
    "AE",
    "GB",
    "US",
    "VI",
    "UY",
    "UZ",
    "VU",
    "VA",
    "VE",
    "VN",
    "WF",
    "EH",
    "YE",
    "ZM",
    "ZW",
  ];

  return (
    <>
      <ModalHeader>{isEditing ? "Edit" : "Create"} address</ModalHeader>
      <FormikForm<ICreateAddressFormValues>
        onSubmit={onSubmit}
        validateOnChange={false}
        initialValues={initialValues}
        validation={CreateAddressValidationSchema}
        enableReinitialize={true}
      >
        {({ values }) => {
          return (
            <>
              <ModalBody>
                <Stack spacing={6} divider={<Divider />}>
                  <PageSection title="Contact information" headingSize="base">
                    <Stack spacing={3}>
                      <div className="grid grid-cols-2 gap-5">
                        <FormikField<
                          ICreateAddressDto,
                          "firstName"
                        > name="firstName">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="First name"
                                errorMessage={errorMessage}
                                isRequired={false}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                        <FormikField<
                          ICreateAddressDto,
                          "lastName"
                        > name="lastName">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="Last name"
                                errorMessage={errorMessage}
                                isRequired={true}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                      </div>
                      <FormikField<
                        ICreateAddressFormValues,
                        "company"
                      > name="company">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="Company"
                              errorMessage={errorMessage}
                            >
                              <Input
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>

                      <FormikField<
                        ICreateAddressFormValues,
                        "email"
                      > name="email">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="Email"
                              errorMessage={errorMessage}
                              isRequired={true}
                            >
                              <Input
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>
                      <FormikField<
                        ICreateAddressFormValues,
                        "phone"
                      > name="phone">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="Phone"
                              errorMessage={errorMessage}
                            >
                              <Input
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>
                    </Stack>
                  </PageSection>
                  <PageSection title="Address information" headingSize="base">
                    <Stack spacing={3}>
                      <FormikField<
                        ICreateAddressFormValues,
                        "countryIsoCode"
                      > name="countryIsoCode">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="Country"
                              errorMessage={errorMessage}
                              isRequired={true}
                            >
                              <CountrySelect
                                onChange={(value) => {
                                  onChange(value);
                                  setQuery("");
                                }}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>
                      {supportedCountries.includes(values.countryIsoCode) &&
                      getEnvironment() === Environments.production ? (
                        <FormControl label="Address search" className="mb-3">
                          <AddressTypeahead
                            isoCode={values.countryIsoCode}
                            setQuery={setQuery}
                            query={query}
                            populateAddressData={(data, isoCode) =>
                              populateAddressData(data, isoCode, values)
                            }
                          />
                        </FormControl>
                      ) : null}
                      <FormikField<
                        ICreateAddressFormValues,
                        "addressLine1"
                      > name="addressLine1">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="Address line 1"
                              isRequired={true}
                              errorMessage={errorMessage}
                            >
                              <Input
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>
                      <FormikField<
                        ICreateAddressFormValues,
                        "addressLine2"
                      > name="addressLine2">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="Address line 2"
                              errorMessage={errorMessage}
                            >
                              <Input
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>
                      <FormikField<
                        ICreateAddressFormValues,
                        "city"
                      > name="city">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="City"
                              errorMessage={errorMessage}
                              isRequired={true}
                            >
                              <Input
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>
                      <FormikField<
                        ICreateAddressFormValues,
                        "countyState"
                      > name="countyState">
                        {({ onChange, value, errorMessage }) => {
                          return (
                            <FormControl
                              label="County / state"
                              errorMessage={errorMessage}
                            >
                              {/* <StateSelect
                                onChange={(value) => {
                                  onChange(value);
                                }}
                                countryIsoCode={values.countryIsoCode}
                                value={value}
                              /> */}
                              <Input
                                onChange={(e) => onChange(e.target.value)}
                                value={value}
                              />
                            </FormControl>
                          );
                        }}
                      </FormikField>
                      <div className="grid grid-cols-2 gap-5">
                        <FormikField<
                          ICreateAddressFormValues,
                          "zip"
                        > name="zip">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="Postcode / Zip"
                                errorMessage={errorMessage}
                                isRequired={true}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                        <FormikField<
                          ICreateAddressFormValues,
                          "code"
                        > name="code">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="Code"
                                errorMessage={errorMessage}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                      </div>
                      <div className="grid grid-cols-2 gap-5">
                        <FormikField<
                          ICreateAddressFormValues,
                          "eoriNumber"
                        > name="eoriNumber">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="EORI"
                                errorMessage={errorMessage}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                        <FormikField<
                          ICreateAddressFormValues,
                          "vatNumber"
                        > name="vatNumber">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="VAT Number"
                                errorMessage={errorMessage}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                      </div>
                      <div className="grid grid-cols-2 gap-5">
                        <FormikField<
                          ICreateAddressFormValues,
                          "mobile"
                        > name="mobile">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="Mobile"
                                errorMessage={errorMessage}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                        <FormikField<
                          ICreateAddressFormValues,
                          "branch"
                        > name="branch">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="Branch"
                                errorMessage={errorMessage}
                              >
                                <Input
                                  onChange={(e) => onChange(e.target.value)}
                                  value={value}
                                />
                              </FormControl>
                            );
                          }}
                        </FormikField>
                      </div>
                      <div className="grid grid-cols-2 gap-5">
                        <FormikField<
                          ICreateAddressFormValues,
                          "residentialAddress"
                        > name="residentialAddress">
                          {({ onChange, value, errorMessage }) => {
                            return (
                              <FormControl
                                label="Residential Address"
                                errorMessage={errorMessage}
                              >
                                <Checkbox
                                  checked={value}
                                  onCheckedChange={(checked) => {
                                    onChange(checked as boolean);
                                  }}
                                ></Checkbox>
                              </FormControl>
                            );
                          }}
                        </FormikField>
                      </div>
                    </Stack>
                  </PageSection>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <div className="ml-auto">
                  <Stack spacing={3} direction="horizontal">
                    <Button onClick={() => onClose()}>Cancel</Button>
                    <Button
                      colorScheme="primary"
                      type="submit"
                      isLoading={isSubmitting}
                    >
                      {isEditing ? "Save changes" : "Create address"}
                    </Button>
                  </Stack>
                </div>
              </ModalFooter>
            </>
          );
        }}
      </FormikForm>
    </>
  );
};
