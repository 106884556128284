import { AppThunk } from "store";
import { fetchGeneratedReference } from "services/api/quotes";
import { setCurrentQuoteReference } from "../index.slice";

export const generateRatesThunk = (adminSalesAccountUUID: string): AppThunk => {
  return async (dispatch, getState) => {
    const { data, error } = await fetchGeneratedReference(
      adminSalesAccountUUID
    );
    if (!error && data) {
      dispatch(setCurrentQuoteReference(data));
    } else {
      return;
    }
  };
};
