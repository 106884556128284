import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { createAdminQuote, createQuote } from "services/api/quotes";
import { AppThunk, RootState } from "store";
import { isAdminMode } from "utils/isAdminMode";
import {
  setErrors,
  setRates,
  setRatesError,
  setRatesLoadingStatus,
  setSubmitCount,
  setCurrentQuoteReference,
} from "../index.slice";
import { CreateShipmentValidator } from "../validators/CreateShipmentsSlice.validator";

export const fetchRatesThunk = (): AppThunk => {
  return async (dispatch, getState) => {
    const {
      countriesSlice: { countries },
      authSlice: { salesAccountUUID, loggedInUser, financialCompany },
      createShipmentSlice,
    } = getState() as RootState;

    const {
      values,
      type,
      salesAccountUUID: adminSalesAccountUUID,
      pickUpObject: { shouldPickUp },
      shippingToResidence,
      dangerousGoodsOption,
      currentQuoteReference,
    } = createShipmentSlice;
    let isValid = false;

    if (type === ShipmentTypes.SHIPMENT) {
      const { errors, isValid: isShipmentValid } = new CreateShipmentValidator({
        countries,
      }).validateFetchRates(
        {
          ...values,
        },
        dangerousGoodsOption
      );
      dispatch(setErrors(errors));

      isValid = isShipmentValid;
    }

    if (type === ShipmentTypes.QUOTE) {
      const { errors, isValid: isQuoteValid } = new CreateShipmentValidator({
        countries,
      }).validateQuote({
        ...values,
      });

      dispatch(
        setErrors({
          ...createShipmentSlice.errors,
          ...errors,
        })
      );

      isValid = isQuoteValid;
    }

    dispatch(setSubmitCount());

    if (isValid) {
      dispatch(setRatesLoadingStatus(ApiLoadingStatus.LOADING));

      const fromCountryIso = values.sender.countryIsoCode;

      const toCountryIso = values.receiver.countryIsoCode;

      const payload = {
        fromCountryIso,
        fromZip: values.sender.zip,
        toCountryIso,
        toZip: values.receiver.zip,
        items: values.items,
        financialCompany: financialCompany,
        shouldPickUp: shouldPickUp,
        shippingToResidence: shippingToResidence,
        ...(type === ShipmentTypes.QUOTE && { currentQuoteReference }),
      };

      const { data, error, message } = isAdminMode(
        loggedInUser,
        salesAccountUUID
      )
        ? await createAdminQuote({
            ...payload,
            salesAccountUUID: adminSalesAccountUUID,
          })
        : await createQuote(salesAccountUUID, payload);

      if (!error && data) {
        if (type === ShipmentTypes.SHIPMENT) {
          dispatch(setCurrentQuoteReference(data.jobNumber));
        }
        dispatch(setRates(data.rates));
        dispatch(setRatesLoadingStatus(ApiLoadingStatus.SUCCESS));
      }

      if (error) {
        dispatch(setRatesLoadingStatus(ApiLoadingStatus.FAILED));
        dispatch(setRatesError(message || ""));
      }
    }
  };
};
