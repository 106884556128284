import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { CreateQuoteSteps } from "../CreateShipmentForm/CreateShipmentForm.config";
import { CreateShipmentRates } from "../common/CreateShipmentFormRates";
import { CreateShipmentFormSteps } from "../CreateShipmentForm/CreateShipmentFormSteps";
import { CreateQuoteFooter } from "./CreateQuoteFooter";
import { isUserAdmin } from "utils/isUserAdmin";
import { closeModal, openModal } from "store/modalSlice";
import { SalesAccountStatus } from "enum/sales-account-status.enum";
import { fetchRatesThunk } from "store/createShipmentSlice/thunks/fetchRatesThunk.thunk";
import { Divider } from "components/atoms/Divider";
import { Input } from "components/atoms/Input";
import { setCurrentQuoteReference } from "store/createShipmentSlice/index.slice";
import { useEffect } from "react";
import { generateRatesThunk } from "store/createShipmentSlice/thunks/generateReferenceThunk";
import { validateJobNumberThunk } from "store/createShipmentSlice/thunks/validateJobNumberThunk";
import { ShipmentTypes } from "enum/shipment-types.enum";
import { FormControl } from "components/molecules/FormControl";

export const CreateQuoteForm = () => {
  const dispatch = useDispatch();

  const createShipment = useSelector((state: RootState) => {
    return state.createShipmentSlice;
  });

  const { loggedInUser, salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const handleSubmitQuote = (status: string) => {
    // Admin should be able to quote, even if customer is on stop – but a warning should be shown.
    // Requested here: https://github.com/ITD-Global/itd-connexx/issues/129
    if (status === "SUCCESS") {
      dispatch(closeModal({ modalName: "createShipment" }));
    }
    if (isUserAdmin(loggedInUser)) {
      const customer = salesAccounts.find(
        (salesAccount) => salesAccount.uuid === createShipment.salesAccountUUID
      );
      if (customer?.status === SalesAccountStatus.STOPPED)
        dispatch(openModal({ name: "customerOnStopWarning" }));
      else dispatch(fetchRatesThunk());
    } else {
      dispatch(fetchRatesThunk());
    }
  };

  useEffect(() => {
    dispatch(generateRatesThunk(salesAccountUUID));
  }, []);

  useEffect(() => {
    if (
      createShipment.currentQuoteReference &&
      createShipment.type !== ShipmentTypes.SHIPMENT
    ) {
      dispatch(
        validateJobNumberThunk(
          salesAccountUUID,
          createShipment.currentQuoteReference
        )
      );
    }
  }, [createShipment.currentQuoteReference]);

  return (
    <>
      <CreateShipmentFormSteps steps={CreateQuoteSteps()} />
      {createShipment.ratesLoadingStatus !== ApiLoadingStatus.IDLE && (
        <>
          <Divider />
          <CreateShipmentRates />
        </>
      )}
      {createShipment.ratesLoadingStatus === ApiLoadingStatus.IDLE ? (
        <div className="flex justify-center mb-8">
          <div className="flex text-xl font-semibold mr-2">Reference: </div>
          <div className="flex text-xl">
            <FormControl
              isInvalid={createShipment.currentQuoteReferenceIsValid}
              errorMessage={
                createShipment.currentQuoteReferenceIsValid
                  ? null
                  : "Job Number already exists"
              }
            >
              <Input
                onChange={(e) => {
                  dispatch(setCurrentQuoteReference(e.target.value));
                }}
                value={createShipment.currentQuoteReference}
              />
            </FormControl>
          </div>
        </div>
      ) : null}
      {createShipment.currentQuoteReference &&
      createShipment.ratesLoadingStatus === ApiLoadingStatus.SUCCESS ? (
        <div className="flex justify-center mb-8">
          <div className="flex text-2xl font-bold mr-2">Reference: </div>
          <div className="flex text-2xl">
            {createShipment.currentQuoteReference}
          </div>
        </div>
      ) : null}
      <div className="mt-2 px-10 pb-10">
        <div>Disclaimers:</div>
        <div className="mb-8 mt-3">
          <div style={{ fontSize: "13px" }} className="flex">
            <ul
              style={{
                marginLeft: "12px",
                listStyleType: "initial",
              }}
            >
              <li>
                The above price includes freight & fuel surcharge on the freight
                cost only.
              </li>
              <li>
                These prices are correct at the time of quotation and is valid
                till the end of the week when fuel surcharge is subject to
                change.
              </li>
              <li>
                The prices in some instances may not include local destination /
                charges.
              </li>
              <li>
                Shipments may incur duty & taxes levied by customs in the
                destination country. The above quotes do not include duty &
                taxes charges.
              </li>

              <li>
                These quotes do not include additional surcharges such as
                Brexit, Covid, Residential, Oversize or Additional handling
                charges (which may also be subject to fuel surcharge). Please
                refer to your additional surcharges sheet.
              </li>
              <li>
                Each Carrier reserves the right to audit each parcel. If the
                Audited Chargeable Weight differs from the quoted weight (based
                on the weight & dimensions supplied at time of quote) then final
                billable price will be adjusted according to the Carrier’s
                Audited weight.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CreateQuoteFooter
        handleSubmit={handleSubmitQuote}
        loadingStatus={createShipment.ratesLoadingStatus}
        isDisabled={
          !createShipment.currentQuoteReferenceIsValid &&
          createShipment.currentQuoteReference !== ""
        }
      />
    </>
  );
};
